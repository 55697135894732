import React, { useContext } from 'react'
import AuthContext from 'context/AuthContext'
import { Container, Navbar, Nav, Dropdown } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import Loader from 'layout/Loader'

export default function HomeHeader () {
  const { t, i18n } = useTranslation()
  let { user, language, setLanguage } = useContext(AuthContext)
  const changeLanguageHandler = eventKey => {
    setLanguage(eventKey)
    if (eventKey == 2) {
      localStorage.setItem('language', 'al')
    } else {
      localStorage.setItem('language', 'en')
    }
    if (eventKey == 1) {
      i18n.changeLanguage('en')
    } else if (eventKey == 2) {
      i18n.changeLanguage('al')
    }
  }
  return (
    <>
      <Loader />
      <div className='header'>
        <Navbar className='p-0' expand='lg'>
          <Container>
            <Navbar.Brand href={'/'}>
              <img src='../images/logo.png' alt='' />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls='basic-navbar-nav' />
            <Navbar.Collapse id='basic-navbar-nav'>
              <Nav className='ms-auto'>
                <Nav.Link href={'/'}>
                  <a
                    className='ahref'
                    href='https://play.google.com/store/games?gl=US'
                    target='_blank'
                    rel='noreferrer'
                  >
                    {t('HOME_HEADER_RATE_APP')}
                  </a>
                </Nav.Link>
                <Nav.Link href={'/about-us'}>{t('FOOTER_ABOUT_US')}</Nav.Link>
                <Nav.Link href={'/contact-us'}>
                  {t('FOOTER_CONTACT_US')}
                </Nav.Link>
                <Nav.Link href={'/faqs'}>{t('FOOTER_FAQS')}</Nav.Link>
              </Nav>
            </Navbar.Collapse>
            {user ? (
              <Nav.Link
                data-toggle='tooltip'
                data-placement='bottom'
                title='Go to dashboard'
                href={'/dashboard'}
                className='me-sm-3 me-2 btn btn_theme px-3'
              >
                {t('HOME_HEADER_LOGGED_IN')} {user?.first_name || 'User'}
              </Nav.Link>
            ) : (
              <Nav.Link as={Link} to='/login' className='me-sm-3 me-2'>
                {t('HOME_HEADER_LOGIN')}
              </Nav.Link>
            )}
            {user ? null : (
              <Nav.Link as={Link} to='/register' className='me-sm-3 me-2'>
                {t('O_REGISTER_TAB')}
              </Nav.Link>
            )}
            <div className='language me-2' title={t('O_LANGUAGE')}>
              <Dropdown onSelect={changeLanguageHandler}>
                <Dropdown.Toggle
                  className='bg-transparent border-0 text-dark'
                  id='dropdown-basic'
                  value='En'
                >
                  <img
                    src={
                      language == 1
                        ? './images/usFlag.svg'
                        : './images/albaniaFlag.svg'
                    }
                    className='me-2 rounded-circle'
                    alt=''
                  />{' '}
                  <span>{language == 1 ? 'En' : 'Al'}</span>
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item eventKey='1'>
                    <img src='./images/usFlag.svg' className='me-2' alt='' />{' '}
                    <span>En</span>
                  </Dropdown.Item>
                  <Dropdown.Item value='En' eventKey='2'>
                    <img
                      src='./images/albaniaFlag.svg'
                      className='me-2 rounded-circle'
                      alt=''
                    />{' '}
                    <span>Al</span>
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
            <Nav.Link
              className='btn btn_theme px-3'
              target={'_blank'}
              href={process.env.REACT_APP_BECOME_MERCHANT_URL}
            >
              {t('HOME_HEADER_BECOME A MERCHANT')}
            </Nav.Link>
          </Container>
        </Navbar>
      </div>
    </>
  )
}
