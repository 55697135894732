import React, { createContext, useState, useEffect } from 'react'
import jwt_decode from 'jwt-decode'
import { useNavigate } from 'react-router-dom'
import { apiPost, apiPut } from '../utils/apiFetch'
import apiPath from '../utils/apiPath'
import useToastContext from 'hooks/useToastContext'
import { useTranslation } from 'react-i18next'
const AuthContext = createContext()

export default AuthContext

export const AuthProvider = ({ children }) => {
  const { i18n } = useTranslation()
  let [user, setUser] = useState(() =>
    localStorage.getItem('token')
      ? jwt_decode(localStorage.getItem('token'))
      : null
  )

  const navigate = useNavigate()
  const notification = useToastContext()
  const [lastActivity, setLastActivity] = useState(new Date())
  const [language, setLanguage] = useState(
    localStorage.getItem('language') === 'en' ? 1 : 2
  )
  let loginUser = async body => {
    const { data } = await apiPost(apiPath.loginUser, body, {
      params: {
        loginType: 'password'
      }
    })
    if (data.success) {
      notification.success(data.message)
      navigate('/login/otp', {
        state: {
          otpID: data?.results?.otpID,
          email: body?.email,
          mobile: body?.mobile,
          password: body?.password
        }
      })
    } else {
      if (
        data.results != null &&
        data?.results?.is_mobile_verified === 0 &&
        data?.results?.is_email_verified === 0
      ) {
        navigate('/register/otp', {
          state: {
            country_code: data?.results?.country_code,
            phone: data?.results?.mobile,
            email: data?.results?.email
          }
        })
      } else {
        notification.error(data.message)
      }
    }
  }

  const updateProfile = async (formData, callback) => {
    try {
      let config = {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
      const res = await apiPut(apiPath.updateProfile, formData, config)
      notification.success(res.data.message)
      const token = res?.data?.results?.token || null
      const refresh_token = res?.data?.results?.refresh_token || null
      localStorage.setItem('token', token)
      localStorage.setItem('refresh_token', refresh_token)
      setUser(jwt_decode(token))
      callback()
    } catch (err) {
      console.log('err:', err)
    }
  }

  let logoutUser = () => {
    setUser(null)
    localStorage.removeItem('token')
    localStorage.removeItem('refresh_token')
    navigate('/')
  }

  useEffect(() => {
    if (localStorage.getItem('language') === 'en') {
      i18n.changeLanguage('en')
    } else {
      i18n.changeLanguage('al')
    }
  }, [])
  let contextData = {
    user: user,
    loginUser: loginUser,
    logoutUser: logoutUser,
    updateProfile: updateProfile,
    setLanguage,
    setUser,
    setLastActivity,
    lastActivity,
    language
  }
  return (
    <AuthContext.Provider value={contextData}>{children}</AuthContext.Provider>
  )
}
