import AuthContext from 'context/AuthContext'
import React, { useContext, useEffect, useState } from 'react'
import { Form, Col, Row, Button, Spinner, Container } from 'react-bootstrap'
import { Link, useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import ErrorMessage from 'components/reusable/ErrorMessage'
import { generateRandomID } from 'utils/reusableMethods'
import { validationRules, validationRegex } from 'utils/constants'
import { AiFillEye, AiFillEyeInvisible } from 'react-icons/ai'
import { useTranslation } from 'react-i18next'

const Login = ({ activeKey }) => {
  const { t } = useTranslation()
  const {
    register,
    handleSubmit,
    reset,
    setError,
    clearErrors,
    formState: { errors }
  } = useForm({ mode: 'onChange', shouldFocusError: true, defaultValues: {} })

  let { loginUser } = useContext(AuthContext)
  const [icon2, setIcon2] = useState(true)
  const [loading, setLoading] = useState(false)
  const [rememberMe, setRememberMe] = useState(
    JSON.parse(localStorage.getItem('rememberMe'))
      ? JSON.parse(localStorage.getItem('rememberMe'))
      : false
  )
  let latestWindow

  const handleLogin = data => {
    let sendData = {
      device_id: generateRandomID(10),
      device_type: 'web',
      device_token: generateRandomID(20)
    }
    try {
      setLoading(true)
      if (!isNaN(data?.email_or_phone)) {
        sendData.phone = data?.email_or_phone
      } else {
        sendData.email = data?.email_or_phone
      }

      sendData.password = data.password
      if (rememberMe) {
        localStorage.setItem('email_or_phone', data?.email_or_phone)
        localStorage.setItem('password', data?.password)
      }
      loginUser(sendData)
    } catch (err) {}

    setLoading(false)
  }

  useEffect(() => {
    reset()
  }, [activeKey])

  useEffect(() => {
    if (rememberMe) {
      reset({
        email_or_phone: localStorage.getItem('email_or_phone'),
        password: localStorage.getItem('password')
      })
    } else {
      localStorage.removeItem('email_or_phone')
      localStorage.removeItem('password')
    }
  }, [])

  const handleCheckboxChange = e => {
    setRememberMe(e.target.checked)
    localStorage.setItem('rememberMe', e.target.checked)
  }
  const navigate = useNavigate()

  function backToHome () {
    navigate('/')
  }

  const preventSpace = e => {
    if (e?.which === 32) {
      e.preventDefault()
    }
  }

  const preventSpacePass = e => {
    e.target.value = e.target.value.trimStart()
    e.target.value = e.target.value.replace(/  +/g, ' ')
  }
  useEffect(() => {
    if (latestWindow) {
      latestWindow.onload = function () {
        var latestURL = latestWindow.location.href
        console.log(latestURL, ' latestWindow')
      }
    }
  }, [latestWindow])
  return (
    <Container>
      <div className='bg-white main_panel p-0 overflow-hidden register_wrap'>
        <Row>
          <Col lg={6}>
            <form onSubmit={handleSubmit(handleLogin)}>
              <Row className='p-md-5 p-3'>
                <Col sm={12}>
                  <Col sm={12}>
                    <h4 className=' text-center mb-4'>{t('O_LOGIN_TAB')}</h4>
                  </Col>
                  <Form.Group className='mb-3'>
                    <Form.Floating className='mb-3'>
                      {
                        <Form.Control
                          id='email_or_phone'
                          type='text'
                          autoFocus={true}
                          maxLength={50}
                          placeholder='name@example.com'
                          onKeyDown={e => preventSpace(e)}
                          {...register('email_or_phone', {
                            required: t('REGISTER_EMAIL_PHONE'),
                            validate: value => {
                              if (!isNaN(value)) {
                                clearErrors('email_or')
                                value.length > 9
                                  ? setError('email_or', {
                                      type: 'require',
                                      message: t('REGISTER_MIN_LENGTH_MOBILE')
                                    })
                                  : clearErrors('email_or')
                              } else {
                                validationRegex.email.test(value)
                                  ? clearErrors('email_or')
                                  : setError('email_or', {
                                      type: 'require',
                                      message: t('REGISTER_EMAIL_EX')
                                    })
                              }
                            }
                          })}
                        />
                      }
                      <label htmlFor='email_or_phone'>
                        {t('LOGIN_EMAIL_MOBILE')}
                        <span className='text-danger'>*</span>
                      </label>
                      <ErrorMessage
                        message={
                          errors?.email_or_phone?.message
                            ? errors?.email_or_phone?.message
                            : errors?.email_or?.message
                        }
                      />
                    </Form.Floating>
                  </Form.Group>
                </Col>
                <Col sm={12}>
                  <Form.Group className='mb-3'>
                    <Form.Floating className='mb-3'>
                      <Form.Control
                        id='password'
                        type={icon2 ? 'password' : 'text'}
                        maxLength={15}
                        placeholder='name@example.com'
                        onInput={preventSpacePass}
                        autoComplete='new-password'
                        {...register('password', {
                          required: t('REGISTER_PASSWORD'),
                          pattern: {
                            value: validationRules.password,
                            message: validationRules.passwordMessage
                          }
                        })}
                      />
                      <label htmlFor='password'>
                        {t('O_PASSWORD')}
                        <span className='text-danger'>*</span>
                      </label>
                      {icon2 ? (
                        <span
                          className='password_view cursor-pointer absolute top-[18px] right-[20px]'
                          style={{
                            position: 'absolute',
                            top: '18px',
                            right: '20px',
                            cursor: 'pointer'
                          }}
                          onClick={() => setIcon2(!icon2)}
                        >
                          <AiFillEyeInvisible />
                        </span>
                      ) : (
                        <span
                          className='password_view cursor-pointer absolute top-[18px] right-[20px]'
                          onClick={() => setIcon2(!icon2)}
                          style={{
                            position: 'absolute',
                            top: '18px',
                            right: '20px',
                            cursor: 'pointer'
                          }}
                        >
                          <AiFillEye />
                        </span>
                      )}
                      <ErrorMessage message={errors?.password?.message} />
                    </Form.Floating>
                  </Form.Group>
                </Col>

                <Col sm={6}>
                  <Form.Group className='mb-sm-4 mb-2'>
                    <span className='custom_check'>
                      {t('O_REMEMBER_ME')}
                      <input
                        type='checkbox'
                        checked={rememberMe}
                        onChange={e => handleCheckboxChange(e)}
                      />
                      <span className='check_indicator'>&nbsp;</span>
                    </span>
                  </Form.Group>
                </Col>
                <Col sm={6} className='text-sm-end mb-2'>
                  <Link to='/forgot-password' className='link'>
                    {t('FORGOT_PASSWORD')}?
                  </Link>
                </Col>
                <Col sm={12}>
                  {loading ? (
                    <Button className='btn btn_theme w-100 py-3 mb-3'>
                      <Spinner animation='border' size='sm' />
                    </Button>
                  ) : (
                    <Button
                      className='btn btn_theme w-100 py-3 mb-3'
                      type='submit'
                    >
                      {t('O_LOGIN_TAB')}
                    </Button>
                  )}
                </Col>
                <Col sm={10} className='text-center w-100 mb-2 mt-4'>
                  {t('LOGIN_DO_NOT')}
                  <Link to='/register' className='link'>
                    {' '}
                    {t('LOGIN_REGISTER_HERE')}
                  </Link>
                </Col>
                <Col sm={8} className='text-center w-100 mb-2 mt-4'>
                  {t('LOGIN_BACK_TO')}
                  <a
                    onClick={() => backToHome()}
                    className='link'
                    style={{ cursor: 'pointer' }}
                  >
                    {' '}
                    {t('LOGIN_HOMES')}
                  </a>
                </Col>
              </Row>
            </form>
          </Col>
          <Col
            lg={6}
            className='login_right d-none text-center bg-light p-4 d-lg-flex align-items-center justify-content-center'
          >
            <img src='./images/register_img.png' alt='' />
          </Col>
        </Row>
      </div>
    </Container>
  )
}

export default Login
