import AuthContext from 'context/AuthContext'
import React, { useContext, useEffect, useState } from 'react'
import { Container, Accordion, Row, Col, Modal } from 'react-bootstrap'
import pathObj from '../utils/apiPath'
import Helper from '../utils/helpers'
import { apiGet } from '../utils/apiFetch'
import parse from 'html-react-parser'
import HomeHeader from 'components/HomeHeader'
import HomeFooter from 'components/HomeFooter'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import jwt_decode from 'jwt-decode'
import useToastContext from 'hooks/useToastContext'
import axios from 'axios'

function Home () {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const notification = useToastContext()
  const { language, setUser } = useContext(AuthContext)
  const [aboutus, setAboutus] = useState()
  const [contactus, setContactus] = useState()
  const [faqs, setFaqs] = useState()
  const [codeResult, setCodeResult] = useState()
  const [show, setShow] = useState(false)

  const getStaticContent = async () => {
    const pathContent = pathObj.staticContentSlug
    const resultAbout = await apiGet(pathContent + '=' + 'about-us')
    const resultContact = await apiGet(pathContent + '=' + 'contact-us')
    const resultFAQ = await apiGet(pathObj.getFaqs)
    setAboutus(resultAbout?.data?.results?.content)
    setContactus(resultContact?.data?.results?.content)
    setFaqs(resultFAQ?.data?.results)
  }
  useEffect(() => {
    getStaticContent()
  }, [language])

  const ssoLogin = async queryToken => {
    console.log('before', queryToken)
    const { data } = await axios.post(
      process.env.REACT_APP_API_BASE_URL + pathObj.ssoLogin,
      {
        device_token: ''
      },
      { headers: { Authorization: 'Bearer ' + queryToken.slice(0) } }
    )
    if (data.success) {
      const token = data?.results?.token || null
      const refresh_token = data?.results?.refresh_token || null
      localStorage.setItem('token', token)
      localStorage.setItem('refresh_token', refresh_token)
      setUser(jwt_decode(token))
      notification.success(data.message)
      navigate('/dashboard')
    }
  }
  useEffect(() => {
    console.log('location', Helper.getParameterByName('access_token'))
    const token = Helper.getParameterByName('access_token')
    ssoLogin(token)
  }, [])

  const handleClose = () => setShow(false)
  return (
    <div>
      <HomeHeader />
      <div className='banner-part'>
        <Container>
          <div className='banner-content-row'>
            <div className='banner-caption'>
              <h1>{t('HOME_SHOP')}</h1>
              <p>
                {t('HOME_SHOP_LINE_1')}
                <br />
                {t('HOME_SHOP_LINE_2')}
                <br />
                {t('HOME_SHOP_LINE_3')}
              </p>

              <div className='download-link'>
                <strong>{t('HOME_AVAILABLE_ON')} </strong>
                <a href='https://www.apple.com/app-store/' target='_blank'>
                  <img src='../images/appstore.png' alt='' />
                </a>
                <a
                  href='https://play.google.com/store/apps/details?id=com.app.alpay'
                  target='_blank'
                >
                  <img src='../images/googleplay.png' alt='' />
                </a>
              </div>
            </div>

            <div className='banner-main-img'>
              <img
                src={
                  language == 1
                    ? '../images/new/banner-top.png'
                    : './images/new/al_banner-top.png'
                }
                alt=''
              />
            </div>
          </div>
        </Container>
      </div>

      <section className='crytech-feature'>
        <Container>
          <Row>
            <Col lg={4}>
              <div className='feature-img'>
                <figure>
                  <img
                    alt=''
                    src={
                      language == 1
                        ? '../images/players.png'
                        : './images/playersAl.png'
                    }
                  />
                </figure>
              </div>
            </Col>

            <Col lg={8}>
              <div className='feature-points'>
                <h2 className='section-title'>
                  {t('HOME_APP_FEATURE')}{' '}
                  <span className='d-block'>{t('HOME_SIMPLE')}</span>
                </h2>
                <ul>
                  <li>
                    <h5>{t('HOME_APP_FEATURE_HEADING_LINE1')}</h5>
                    <p>{t('HOME_APP_FEATURE_PARA_LINE1')}</p>
                  </li>

                  <li>
                    <h5>{t('HOME_APP_FEATURE_HEADING_LINE2')}</h5>
                    <p>{t('HOME_APP_FEATURE_PARA_LINE2')}</p>
                  </li>

                  <li>
                    <h5>{t('HOME_APP_FEATURE_HEADING_LINE3')}</h5>
                    <p>{t('HOME_APP_FEATURE_PARA_LINE3')}</p>
                  </li>
                  <li>
                    <h5>{t('HOME_APP_FEATURE_HEADING_LINE4')}</h5>
                    <p>{t('HOME_APP_FEATURE_PARA_LINE4')}</p>
                  </li>
                  <li>
                    <h5>{t('HOME_APP_FEATURE_HEADING_LINE5')}</h5>
                    <p>{t('HOME_APP_FEATURE_PARA_LINE5')}</p>
                  </li>
                  <li>
                    <h5>{t('HOME_APP_FEATURE_HEADING_LINE6')}</h5>
                    <p>{t('HOME_APP_FEATURE_PARA_LINE6')}</p>
                  </li>
                </ul>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <section className='app-showcase'>
        <Container>
          <h2 className='section-title text-center'>{t('HOME_APP_SCREENS')}</h2>
          <div className='text-center'>
            <img
              alt=''
              src={
                language == 1
                  ? '../images/new/app_screen.png'
                  : '../images/new/app_screenAl.png'
              }
            />
          </div>
        </Container>
      </section>

      <section
        className='aboutus-sec py-lg-5 py-md-3 py-2 text-center'
        id='aboutus'
      >
        <Container>{aboutus ? parse(aboutus) : null}</Container>
      </section>

      <section className='faq-sec'>
        <Container className=''>
          <h2 className='section-title text-center mt-5'>FAQ</h2>
          <div className='row align-items-center'>
            <Col md={7}>
              <Accordion defaultActiveKey='0'>
                {faqs?.map((item, i) => {
                  return (
                    <Accordion.Item eventKey={i}>
                      <Accordion.Header>{item?.title}</Accordion.Header>
                      <Accordion.Body>{item?.content}</Accordion.Body>
                    </Accordion.Item>
                  )
                })}
              </Accordion>
            </Col>
            <Col md={5}>
              <div className='ms-lg-3 text-center text-lg-end'>
                <img
                  src={
                    language == 1
                      ? '../images/new/faq-crypt.png'
                      : '../images/new/al-faq-crypt.png'
                  }
                  alt=''
                />
              </div>
            </Col>
          </div>
        </Container>
      </section>

      {/* contactus */}
      <section
        className='aboutus-sec py-lg-5 py-md-3 py-2 text-center'
        id='contactus'
      >
        <Container>{contactus ? parse(contactus) : null}</Container>
      </section>
      <Modal
        show={show}
        onHide={handleClose}
        centered
        className='verify_modal track_modal'
      >
        <Modal.Header closeButton>
          <Modal.Title> </Modal.Title>
        </Modal.Header>
        <Modal.Body className='text-center p-5'>
          <img src='./images/check.png' alt='' className='mb-4 opacity-1' />
          <p className='text-center'>
            {codeResult?.success ? codeResult.message : codeResult}
          </p>
          <p>
            {codeResult?.success &&
              'Our team review your message and get back to you'}
          </p>
        </Modal.Body>
      </Modal>

      <HomeFooter />
    </div>
  )
}

export default Home
